@import 'mixins';

header {
    height: auto;
    background-color: $header-background;
    background-image: $header-background-gradient;
    // padding-top: 70px;
    position: relative;
    box-sizing: border-box;

    #particles-js {
        height: 100%;
        width: 100vw;
        position: absolute;
        box-sizing: border-box;
    }

    #hero {
        padding: 200px 0px;
        width: 500px;
        max-width: 90%;
        margin: 0 auto;
        color: white;
        text-align: center;
        z-index: 100;
    }

    #my-work-btn {
        display: none;
        cursor: pointer;
    }

}