@import 'mixins';
$nav-height: 60px;

nav {
    position: fixed;
    bottom: 0;
    height: $nav-height;
    width: 100vw;
    margin: 0px 0px;
    font-family: 'Roboto', sans-serif;
    color: $offblack;
    font-size: 14px;
    border-top: 1px solid lighten($background, 10%);
    z-index: 100;

    #nav {
        height: $nav-height;
        width: 100vw;

        ul {
            width: 500px;
            max-width: 100%;
            height: $nav-height;
            // background-color: #ddd;
            box-shadow: $main-box-shadow;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            background-color: $nav-background;

            li {
                list-style-type: none;
                display: inline;
                line-height: $nav-height;
                text-transform: uppercase;
                cursor: pointer;
            }

            li:hover {
                color: $accent-color;
            }
        }
    }
}

@media screen and (min-width: 500px) {
    nav {
        margin: 10px 0px;
        top: 0;
        border: none;
        color: $offwhite;
    }

    #nav {
        ul {
            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0) !important;
            background-color: rgba(225,225,225,0.0) !important;
        }
    }
}