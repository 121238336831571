@import 'mixins';

* {
    padding: 0;
    margin: 0;
}

body {
    font-size: 16px;
    width: 100vw;
    font-family: 'Hind', sans-serif;
    background-color: $background;
    overflow-x: hidden;
}

.heading_font {
    font-family: 'Montserrat', sans-serif;
}

button {
    padding: 0.6rem 2.6rem;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    border-radius: $border-radius;
    background-color: $accent-color;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10;
}

.container {
    width: 1200px;
    max-width: 100%;
    padding: 70px 0px;
    margin: 0 auto;
}

h1 {
    font-size: 2.3rem;
}

h2 {
    font-weight: 200;
    font-size: 1.2rem;
    margin: 10px 0px;
}

h3 {
    font-size: 1.4rem;
    text-align: center;
}

h4 {
    text-transform: uppercase;
    font-size: 1.3rem;
}

textarea:focus, input:focus{
    outline: none;
}

footer {
    text-align: center;
    padding: 30px 0px;
    color: $offwhite;
    background-color: darken($header-background, 15%);
}

hr {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, rgba(61, 198, 154, 0), rgba(61, 198, 154, 0.75), rgba(61, 198, 154, 0));
    margin: 20px auto 40px auto;
    width: 200px;

}

.absolute_center {
    height: auto;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}