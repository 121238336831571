@import 'mixins';

#contact {
    background-color: $header-background;
    
    #form_container {
        width: 800px;
        max-width: 90%;
        display: flex;
        height: auto;
        background-color: white;
        margin: 0 auto;
        border-radius: 4px;
        box-shadow: $header-box-shadow;
        flex-wrap: wrap;
        box-sizing: border-box;

        #form, #information {
            flex-grow: 1;
            padding: 30px;
            width: 100%;
        }

        #information {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            box-sizing: border-box;

            h3 {
                text-align: left !important;
            }

            i {
                padding-right: 10px;
                color: $accent-color;
            }

            a {
                text-decoration: none;
                // color: $accent-color;
                color: black;
            }

            p {
                margin-bottom: 15px;
            }
        }

        #form {
            background-color: darken($accent-color, 5%);
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            box-sizing: border-box;

            form {
                width: 100%;
                box-sizing: border-box;

                .input_container {
                    height: 38px;
                    width: 100%;
                    border: 1px solid $background;
                    border-radius: 4px;
                    line-height: 38px;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    box-sizing: border-box;

                    i {
                        width: 8%;
                        color: white;
                    }

                    input {
                        height: 38px;
                        border: none;
                        box-sizing: border-box;
                        width: calc(90% - 10px);
                        background-color: rgba(225,225,225,0);
                        cursor: pointer;
                        color: white;
                    }

                }

                textarea {
                    width: 100%;
                    height: 100px;
                    border-radius: 4px;
                    background-color: rgba(225,225,225,0);
                    font-family: 'Hind', sans-serif;
                    line-height: 38px;
                    box-sizing: border-box;
                    border: 1px solid $background;
                    cursor: pointer;
                    color: white;
                    padding: 10px;
                    text-align: left;
                }

                input::placeholder, textarea::placeholder {
                    color: white;
                }

                button {
                    width: 100%;
                    border: 1px solid $background;
                    cursor: pointer;
                    margin-top: 30px;
                }
            }
        }
    }

}

@media screen and (min-width: 800px) {
    #form_container {
        flex-wrap: nowrap !important;
    }
    #form, #information {
        width: 50% !important;
    }
}

