@import 'mixins';

#work {
    h3 {
        margin-bottom: 20px;
    }
}

.swiper-container {
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 50px;
}
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    background-color: #fff;
    box-shadow: $main-box-shadow;

    .overlay {
        background-color: transparentize($accent-color, 0.1);
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: 0.2s;
        padding: 50px;
        text-align: center;
        box-sizing: border-box;
        color: white;

        .slide_header {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .action {
            margin-top: 10px;
            text-decoration: underline;
        }

    }

}

.swiper-slide-active:hover .overlay {
    opacity: 1;
}